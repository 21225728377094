import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import { makeStyles, createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';

const axios = require('axios');

let theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          outlineWidth: '0px!important',
          borderTop: '1px solid rgba(196,196,196,1) !important',
          borderBottom: '1px solid rgba(196,196,196,1) !important',
          borderLeft: '1px solid rgba(196,196,196,1) !important',
          borderRight: '1px solid rgba(196,196,196,1) !important',
          // borderTop: '1px solid rgba(104,208,175,1) !important',
          // borderBottom: '1px solid rgba(104,208,175,1) !important',
          // borderLeft: '1px solid rgba(104,208,175,1) !important',
          // borderRight: '1px solid rgba(104,208,175,1) !important',
        },
        '&:hover $notchedOutline': {
          borderTop: '1px solid rgba(196,196,196,1) !important',
          borderBottom: '1px solid rgba(196,196,196,1) !important',
          borderLeft: '1px solid rgba(196,196,196,1) !important',
          borderRight: '1px solid rgba(196,196,196,1) !important',
          // borderTop: '1px solid rgba(104,208,175,1) !important',
          // borderBottom: '1px solid rgba(104,208,175,1) !important',
          // borderLeft: '1px solid rgba(104,208,175,1) !important',
          // borderRight: '1px solid rgba(104,208,175,1) !important',
        },
      },
    }
  }
});

theme = responsiveFontSizes(theme)

const useStyles = makeStyles((theme) => ({
  expiry: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderLeft: 'none',
      borderRight: 'none',
      borderRadius: '0px',
    }
  },
  cardholder: {
    '& .MuiOutlinedInput-notchedOutline': {
      // overflow: 'hidden',
      borderBottomLeftRadius: '3px',
      borderTopLeftRadius: '3px',
      borderBottomRightRadius: '0px',
      borderTopRightRadius: '0px',
    }
  },
  cardNum: {
    '& .MuiOutlinedInput-notchedOutline': {
      // overflow: 'hidden',
      borderRight: 'none',
      borderBottomLeftRadius: '3px',
      borderTopLeftRadius: '3px',
      borderBottomRightRadius: '0px',
      borderTopRightRadius: '0px'
    }
  },
  cvc: {
    '& .MuiOutlinedInput-notchedOutline': {
      // overflow: 'hidden',
      borderLeft: 'none',
      borderBottomLeftRadius: '0px',
      borderTopLeftRadius: '0px',
      borderBottomRightRadius: '3px',
      borderTopRightRadius: '3px'
    }
  },
  input: {
    padding: '0',
    '& .MuiOutlinedInput-notchedOutline': {
      // borderRadius: '0px'
    },
  },
  inputwrapper: {
    '&.MuiGrid-item': {
      padding: '0px'
    }
  },
  main: {
    padding: '1em',
    backgroundColor: '#ffffff00'
  },
  space: {
    padding: '1px'
  }
}));

function App(props) {
  const classes = useStyles(props);
  const [publicKey, setPublicKey] = useState('');
  const [sessionID, setSessionID] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [cardHolder, setCardHolder] = useState('');
  const [cardNum, setCardNumber] = useState('');
  const [cardExpiry, setCardExpiry] = useState('');
  const [cardCvc, setCardCvc] = useState('');

  const publicKeyRef = useRef(null);
  const sessionIDRef = useRef(null);
  const cardHolderRef = useRef(null);
  const cardNumRef = useRef(null);
  const cardExpiryRef = useRef(null);
  const cardCvcRef = useRef(null);
  

  useEffect(()=> {
    let url = window.location.href;
    let urlArr = url.split('?');
    if (urlArr[1]) {
      let queryString = urlArr[1];
      let queryStringArr = queryString.split('&')
      queryStringArr.forEach((query) => {
        let queryArr = query.split('=')
        switch (queryArr[0]) {
          case 'pub':
            let base64data = btoa(queryArr[1] + ":");
            setPublicKey(base64data);
            break;
          default:
            break;
        }
      })

      // Set event listener for pay event
        window.addEventListener('message', (message) => {
          if (!message || !message.data) return;
          const messageString = message.data + '';
          const messageArr = messageString.split(' ');
          if (window.parent != window) {
            if (messageArr[0] === 'pay') {
                // Process Card Tokenization Here with public key
                setSessionID(messageArr[1])
                paymentMethod()
              }
          }
          
          // if (message.data === 'lib-close') {
          //     modal.setAttribute('class', 'modal')
          //     resolve(message)
          // }
          // var msg = message.data.split(' ');
      });
      window.parent.postMessage('{"initialLoad": true}', '*')
    }
  },[])

  const paymentMethod = async () => {
    setIsLoading(true)
    try {
      let sessionIDValue = sessionID || ((sessionIDRef && sessionIDRef.current && sessionIDRef.current.value) || "")
      let publicKeyValue = publicKey || ((publicKeyRef && publicKeyRef.current && publicKeyRef.current.value) || "")
      let cardHolderValue = cardHolder || ((cardHolderRef && cardHolderRef.current && cardHolderRef.current.value) || "")
      let cardNumValue = cardNum || ((cardNumRef && cardNumRef.current && cardNumRef.current.value) || "")
      let cardExpiryValue = cardExpiry || ((cardExpiryRef && cardExpiryRef.current && cardExpiryRef.current.value) || "")
      let cardCvcValue = cardCvc || ((cardCvcRef && cardCvcRef.current && cardCvcRef.current.value) || "")
      
      let cardExpArr = cardExpiryValue.split('/')
      cardNumValue = cardNumValue.split("-").join("")

      var response = await axios.post(
        `${process.env.REACT_APP_CUSTOMER_API}/paymentMethods`,
        {
          type: 'card',
          card: {
            name: cardHolderValue,
            number: cardNumValue,
            expiry: {
              month: cardExpArr[0],
              year: cardExpArr[1]
            }
          }
        },
        {
            headers: { 
              "Authorization": `Basic ${publicKeyValue}`,
              "Access-Control-Allow-Origin": "*",
              "X-Lib-Pos-Type": "elements"
            }
        }
      )
      window.parent.postMessage('{"paymentMethodId": "'+ (response && response.data && response.data.id ? response.data.id : "")+'", "sessionSecretIdentity":"'+sessionIDValue+'", "securityPin":"'+cardCvcValue+'"}', '*')
    } catch (err) {
      if (err && err.response) {
        let sessionIDValue = sessionID || ((sessionIDRef && sessionIDRef.current && sessionIDRef.current.value) || "")
        if (err.response.data) err.response.data.sessionSecretIdentity = sessionIDValue
        window.parent.postMessage(`${JSON.stringify(err.response.data)}`, '*')
      }
      setIsLoading(false)
    }
    setIsLoading(false)
  }


  return (
    <ThemeProvider theme={theme}>
    <main className={classes.main}>
      <TextField inputRef={publicKeyRef} type="hidden" value={publicKey} />
      <TextField inputRef={sessionIDRef} type="hidden" value={sessionID} />
      <Grid container spacing={0}>
        <Grid className={classes.inputwrapper} item xs={12} sm={12}>
          <TextField
            disabled={isLoading}
            className={clsx(classes.input, classes.cardholder)} 
            onChange={(e) => { 
              setCardHolder(e.target.value) 
            }}
            inputRef={cardHolderRef}
            placeholder="Cardholder Name" 
            fullWidth 
            variant="outlined"
            inputProps={{
              style: {
                padding: 7
              }
            }}
          />
        </Grid>
        <Grid className={classes.space} item xs={12} sm={12}></Grid>
        <Grid className={classes.inputwrapper} item xs={7} sm={7}>
          <TextField
            disabled={isLoading}
            value={cardNum}
            inputRef={cardNumRef} 
            className={clsx(classes.input, classes.cardNum)} 
            onChange={(e) => {
              let cardNum = e.target.value.split("-").join(""); // remove hyphens
              if (cardNum.length > 0) {
                cardNum = cardNum.match(new RegExp('.{1,4}', 'g')).join("-");
              }
              setCardNumber(cardNum)
            }}
            placeholder="Card Number" 
            fullWidth 
            variant="outlined"
            inputProps={{
              style: {
                padding: 7
              }
            }}
          />
        </Grid>
        <Grid className={classes.inputwrapper} item xs={3} sm={3}>
          <TextField 
            disabled={isLoading}
            className={clsx(classes.input, classes.expiry)} 
            value={cardExpiry} onChange={(e) => {
              if (e.target.value.length == 2 && cardExpiry.length < e.target.value.length) {
                setCardExpiry(e.target.value + "/")
              } else if (e.target.value.length == 3 && (e.target.value.indexOf('/') < 0 )) {
                setCardExpiry(e.target.value)
                setCardExpiry(cardExpiry + '/' + e.target.value[e.target.value.length - 1])
              } else if (e.target.value.length > 5) {
                return
              } else {
                setCardExpiry(e.target.value)
              }
            }} 
            inputRef={cardExpiryRef} 
            placeholder="MM/YY" 
            fullWidth 
            variant="outlined"
            inputProps={{
              style: {
                padding: 7
              }
            }}
          />
        </Grid>
        <Grid className={classes.inputwrapper} item xs={2} sm={2}>
          <TextField 
            disabled={isLoading}
            inputRef={cardCvcRef} 
            className={clsx(classes.input, classes.cvc)} 
            onChange={(e) => { setCardCvc(e.target.value) }}  
            placeholder="CVC" 
            fullWidth 
            variant="outlined"
            inputProps={{
              style: {
                padding: 7
              }
            }}
          />
        </Grid>
      </Grid>
    </main>
    </ThemeProvider>
  );
}


export default App